import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createAction, handleActions } from 'redux-actions';
import {
  getTags as getTagsApi,
  updateTag as updateTagApi,
  deleteTag as deleteTagApi,
  createTag as createTagApi,
 } from 'services/api/tag';
import { convertArrayToMap } from 'utils/array';
import { useAlert } from 'utils/hooks/useAlert';
import { ERROR_CODE_MESSAGE } from 'constants/index';


const GET_TAGS = 'GET_TAGS';
const UPDATE_TAG = 'UPDATE_TAG';
const CREATE_TAG = 'CREATE_TAG';
const DELETE_TAG = 'DELETE_TAG';

const initState = {
  tags: {
    dataMap: {
      Grade: [],
      Subject: [],
      EducationalSystem: [],
      SchoolYear: []
    },
    dataInfo: null,
  },
  isLoding: false,
};




export const useTag = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const { tags, isLoding } = useSelector(state => state.tag);

  const getTagsAction = createAction(GET_TAGS, async params => {
    const category = { category: params };
    const { data, isSuccess, error } = await getTagsApi(category);
    try {
      if (!isSuccess) throw error;
      return {
        dataMap: {
          Grade: data.filter(tag => tag.category === 'Grade'),
          Subject: data.filter(tag => tag.category === 'Subject'),
          EducationalSystem: data.filter(tag => tag.category === 'EducationalSystem'),
          SchoolYear: data.filter(tag => tag.category === 'SchoolYear'),
        },
        idMap: convertArrayToMap(data, 'tagId')
      };
    } catch (error) {
      return {
        tags: {
          data: [],
          dataMap: {},
          dataInfo: null,
          idMap: {}
        }
      };
    }
  });

  const createTagAction = createAction(CREATE_TAG, async ({ label, category }) => {
    const requestParams = {
      label,
      category
    };
    const { data, isSuccess, error } = await createTagApi(requestParams);
    try {
      if (!isSuccess) throw error;
      setAlert('新增標籤成功', 'success');
      return data;
    } catch (error) {
      const { errorCode } = error;
      const errorMessage = ERROR_CODE_MESSAGE[errorCode] || '新增標籤失敗';
      setAlert(errorMessage, 'error');
      return;
    }
  });

  const updateTagAction = createAction(UPDATE_TAG, async (tagId,params)=>{
    const { data, isSuccess, error } = await updateTagApi(tagId,params);
    try {
      if (!isSuccess) throw error;
      setAlert('更新標籤成功', 'success');
      history.push('/tags');
      return data;
    } catch (error) {
      const { errorCode } = error;
      const errorMessage = ERROR_CODE_MESSAGE[errorCode] || '更新標籤失敗';
      setAlert(errorMessage, 'error');
      return;
    }
  });


  const deleteTagAction = createAction(DELETE_TAG, async (tagId) => {
    const { isSuccess, error } = await deleteTagApi(tagId);
    try {
      if (!isSuccess) throw error;
      setAlert('刪除標籤成功', 'success');
      return { isSuccess };
    } catch (error) {
      const { errorCode } = error;
      const errorMessage = ERROR_CODE_MESSAGE[errorCode] || '刪除標籤失敗';
      setAlert(errorMessage, 'error');
      return { isSuccess };
    }
  });

  const getTags = params => dispatch(getTagsAction(params));
  const updateTag = (tagId,params) => dispatch(updateTagAction(tagId,params));
  const createTag = params => dispatch(createTagAction(params));
  const deleteTag = tagId => dispatch(deleteTagAction(tagId));

  return [{ tags, isLoding }, { getTags, updateTag, createTag, deleteTag }];
};

const reducer = handleActions({
  GET_TAGS_PENDING: state => ({
    ...state,
    isLoding: true
  }),
  GET_TAGS_FULFILLED: (state, action) => ({
    ...state,
    tags: { ...state.tags, dataMap: action.payload.dataMap, idMap: action.payload.idMap },
    isLoding: false,
  }),
  GET_TAGS_REJECTED: state => ({
    ...state,
    tags: {
      data: [],
      dataMap: {},
      dataInfo: null,
    },
    isLoding: false
  })
}, initState);

export default reducer;
