import React from 'react';
import PropTypes from 'prop-types';
import getTime from 'date-fns/getTime';


import { UiKeyboardDateTimePicker } from './DateTimePicker.style';


/**
 * 時間日期組件
 */

export const DateTimePicker = ({
  label,
  value,
  format = 'yyyy/MM/dd',
  onChange = () => { },
  cancelLabel = '取消',
  okLabel = '確定',
  clearLabel = '清除',
  inputVariant = 'outlined',
  disablePast = false
}) => {

  const onValueChange = date => onChange(date ? getTime(date) : null);

  return (
    <UiKeyboardDateTimePicker
      variant="dialog"
      inputVariant={inputVariant}
      ampm={false}
      label={label}
      value={value ? new Date(value) : new Date()}
      onChange={onValueChange}
      disablePast={disablePast}
      format={format}
      cancelLabel={cancelLabel}
      okLabel={okLabel}
      clearLabel={clearLabel}
      clearable
    />
  );
};

DateTimePicker.propTypes = {
  label: PropTypes.string,
  inputVariant: PropTypes.string,
  value: PropTypes.number,
  format: PropTypes.string,
  onChange: PropTypes.func,
  cancelLabel: PropTypes.string,
  clearLabel: PropTypes.string,
  okLabel: PropTypes.string,
  disablePast: PropTypes.bool
};


