import { createMuiTheme } from '@material-ui/core/styles';

let theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          WebkitFontSmoothing: 'auto',
          fontFamily: [
            'Noto Sans TC',
            'Helvetica Neue',
            'PingFang TC',
            'Heiti TC',
            'sans-serif'
          ].join(','),
          boxSizing: 'border-box',
          lineHeight: 1.75,
          letterSpacing: 0
        },
        h1: {
          fontSize: '2.5rem',
        },
        h2: {
          fontSize: '2rem',
        },
        h3: {
          fontSize: '1.7rem',
        },
        h4: {
          fontSize: '1.5rem',
        },
        h5: {
          fontSize: '1.2rem',
        },
        h6: {
          fontSize: '1rem',
        },
        a: {
          textDecoration: 'none'
        }
      },
    },
  },
  text: {
    large: '2rem',
    normal: '1.5rem',
    small: '1rem',
    color: {
      error: '#F44336',
    }
  },
  btn: {//todo change btn palette for onebook-bookstore
    normal: '#00923F',
    normalhover: '#03a696',
    menu: '#a1e4ed;',
    info: '#006db2',
    infohover: '#009be5',
    highlight: '#f38a04',
    highlighthover: '#f29f05',
    disable: '#a5a5a5',
    disablehover: '#a5a5a5',
    visibility: '#43a047',
    visibilityhover: '#47a547',
    delete: '#f44336',
    deletehover: '#f55e3f',
    bar: '#a1e6ef',
    barhover: '#71dae0',
    danger: '#F44336',
    dangerhover: '#E91E63',
    operate: '#53917E',
    operatehover: '#5B9F8A',
  },
});

theme.overrides.MuiCssBaseline['@global'].html = {
  [theme.breakpoints.up('xs')]: { //0
    fontSize: '56.25%', // 9 / 16
  },
  [theme.breakpoints.up('sm')]: { //600
    fontSize: '62.5%', // 10 / 16
  },
  [theme.breakpoints.up('md')]: { //960
    fontSize: '75%', // 12 / 16
  },
  [theme.breakpoints.up('lg')]: { //1280
    fontSize: '100%', // 16 / 16
  },
  // [theme.breakpoints.up('xl')]: { //1920
  //   fontSize: '75%',
  // },
};

export const media = {
  xl: `(min-width: ${theme.breakpoints.values.xl}px)`, //1920
  lg: `(min-width: ${theme.breakpoints.values.lg}px)`, //1280
  md: `(min-width: ${theme.breakpoints.values.md}px)`, // 960
  sm: `(min-width: ${theme.breakpoints.values.sm}px)`, // 600
  xs: `(min-width: ${theme.breakpoints.values.xs}px)`, // 0
};

export default theme;
