import React from 'react';
import PropTypes from 'prop-types';
import { UiLoading } from './Loading.style';


/**
 * 等待
 */

export const Loading = ({ isLoading = false }) => {
  return (
    <>
      {
        isLoading && <UiLoading>loading</UiLoading>
      }
    </>
  );
};

Loading.propTypes = {
  isLoading: PropTypes.bool,
};


