import React from 'react';
import routes from 'routes/routesConfig/menuRoutes';
import { UiSideMenu, UiNavLink } from './SideMenu.style';


/**
 * 側邊欄 主選單
 */

export const SideMenu = () => {
  return (
    <UiSideMenu>
      {
        routes.map(route => (
          <UiNavLink key={route.path} to={route.path} activeClassName="active">
            {route.label}
          </UiNavLink>
        ))
      }
    </UiSideMenu>
  );
};



