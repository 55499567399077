import React, { useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useBook } from 'store/book';
import { useTag } from 'store/tag';
import { EDIT_STATUS } from 'constants/index';
import { useSetState } from 'utils/hooks/useSetState';
import { removeRepeatArray } from 'utils/array';
import { useAlert } from 'utils/hooks/useAlert';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { UiForm, UiFormTitle, UiFormItem, UiTextField } from 'styles';
import { UiBookForm, UiBtn, UiAutocomplete } from './BookForm.style';
import { Button, Loading, UploadButton } from 'components';
import noImg from 'assets/images/noImg.png';

/**
 * 書本表單
 */
export const BookForm = ({ editStatus }) => {
  const history = useHistory();
  const location = useLocation();
  const { bookId } = useParams();
  const { setAlert } = useAlert();
  const { upload } = useFirebaseStorage();

  // store
  const [{ books, isLoding }, { getBookById, createBook, updateBook }] = useBook();
  const { data } = books;
  const [{ tags, isLoding: tagsLoading }, { getTags }] = useTag();
  const {
    dataMap: { SchoolYear, EducationalSystem, Grade, Subject },
    idMap,
  } = tags;

  // state
  const [{
    editData,
    displayName,
    bookInfoId,
    isPublish,
    isAppPublish,
    tagList,
    booktags,
    imageFile,
    schoolValues,
    educationalValue,
    gradeValue,
    subjectValues,
    displayNameActive,
    bookInfoIdActive,
    schoolActive,
    educationalActive,
    gradeActive,
    subjectActive,
  }, setState] = useSetState({
    editData: null,
    displayName: '',
    bookInfoId: '',
    isPublish: false,
    isAppPublish: false,
    tagList: [],
    booktags: [],
    imageFile: {},
    schoolValues: [], // 學年度 value
    educationalValue: [], // 學制 value
    gradeValue: [], //  年級 value
    subjectValues: [], // 科目 value
    displayNameActive: false,
    bookInfoIdActive: false,
    schoolActive: false,
    educationalActive: false,
    gradeActive: false,
    subjectActive: false,
  });

  // 書本名稱 input
  const onBookNameChange = e => {
    const name = e.target.value;
    setState({ displayName: name });
  };

  // 書本ＩＤ input
  const onBookIdChange = e => {
    const id = e.target.value;
    setState({ bookInfoId: id });
  };

  // 學年度 select
  const onSchoolYearChange = (e, newValue) => {
    setState({ schoolValues: removeRepeatArray(newValue) });
  };

  // 學制 select
  const oneducationalChange = (e, newValue) => {
    setState({ educationalValue: removeRepeatArray(newValue) });
  };

  // 年級 select
  const onGradeChange = (e, newValue) => {
    setState({ gradeValue: removeRepeatArray(newValue) });
  };

  // 科目 select
  const onSubjectChange = (e, newValue) => {
    setState({ subjectValues: removeRepeatArray(newValue) });
  };

  // 新增書本圖檔資訊
  const onSaveImageFile = (file) => {
    setState({ imageFile: file });
  };

  // 上傳檔案
  const onUploaded = async file => {
    if (!file.type) {
      history.push(`/books/${location.search}`);
      return;
    }
    const pathId = bookId || bookInfoId;
    const uploadPath = `/uploadoutput/${pathId}/cover.jpg`;
    const { status, url } = await upload(uploadPath, file);
    if (status) {
      setAlert('上傳檔案成功!', 'success');
      history.push(`/books/${location.search}`);
      return url;
    } else {
      setAlert('上傳檔案失敗!', 'error');
      return false;
    }
  };

  const onSubmit = async () => {
    if (schoolValues.length <= 0 || educationalValue.length <= 0 || gradeValue.length <= 0 ||
      subjectValues.length <= 0 || displayName.length <= 0 || bookInfoId.length <= 0) {
      setAlert('此欄位為必填', 'error');
      if (schoolValues.length <= 0) setState({ schoolActive: true });
      if (educationalValue.length <= 0) setState({ educationalActive: true });
      if (gradeValue.length <= 0) setState({ gradeActive: true });
      if (subjectValues.length <= 0) setState({ subjectActive: true });
      if (displayName.length <= 0) setState({ displayNameActive: true });
      if (bookInfoId.length <= 0) setState({ bookInfoIdActive: true });
    } else {
      let tags = tagList.map(item => item.tagId);
      tags = [...new Set(tags)];
      let isSuccess = false;
      if (editStatus === EDIT_STATUS.EDIT) {
        const { value } = await updateBook(bookId, { displayName, tags, isPublish, isAppPublish });
        isSuccess = value;
      } else {
        const { value } = await createBook({
          displayName,
          bookId: bookInfoId,
          tags,
          from: process.env.REACT_APP_TARGET
        });
        isSuccess = value;
      }
      if (isSuccess) {
        onUploaded(imageFile);
      }
    }
  };

  // 回上一頁
  const goBack = () => {
    history.push(`/books/${location.search}`);
  };

  useEffect(() => {
    if (!editData) return;
    setState({
      displayName: editData['displayName'],
      bookInfoId: editData['bookId'],
      booktags: editData['tags'],
      isPublish: editData['isPublish'],
      isAppPublish: editData['isAppPublish'],
    });
  }, [editData]);

  useEffect(() => {
    setState({ editData: data[0] });
  }, [books]);

  useEffect(() => {
    if (!booktags) return;
    const SchoolYearOptionValues = SchoolYear.map(item => item.tagId);
    // book 資料的 tags 與 tag 列表做比對
    const filterSchoolValues = booktags.filter(tag => SchoolYearOptionValues.includes(tag)).map(tagId => idMap[tagId]);
    // 去除重複 key
    const nextSchoolValues = removeRepeatArray(filterSchoolValues);
    setState({ schoolValues: nextSchoolValues, });
  }, [SchoolYear, booktags, idMap]);

  useEffect(() => {
    if (!booktags) return;
    const EducationalSystemOptionValues = EducationalSystem.map(item => item.tagId);
    const filterEducationalValue = booktags.filter(tag =>
      EducationalSystemOptionValues.includes(tag)).map(tagId => idMap[tagId]);
    const nextEducationalValue = removeRepeatArray(filterEducationalValue);
    setState({ educationalValue: nextEducationalValue, });
  }, [EducationalSystem, booktags, idMap]);

  useEffect(() => {
    if (!booktags) return;
    const gradeOptionValues = Grade.map(item => item.tagId);
    const filterGradeValue = booktags.filter(tag => gradeOptionValues.includes(tag)).map(tagId => idMap[tagId]);
    const nextGradeValue = removeRepeatArray(filterGradeValue);
    setState({ gradeValue: nextGradeValue, });
  }, [Grade, booktags, idMap]);

  useEffect(() => {
    if (!booktags) return;
    const subjectOptionValues = Subject.map(item => item.tagId);
    const filterSubjectValues = booktags.filter(tag => subjectOptionValues.includes(tag)).map(tagId => idMap[tagId]);
    const nextSubjectValues = removeRepeatArray(filterSubjectValues);
    setState({ subjectValues: nextSubjectValues, });
  }, [Subject, booktags, idMap]);

  useEffect(() => {
    setState({
      tagList: [...schoolValues, ...educationalValue, ...gradeValue, ...subjectValues],
      schoolActive: false,
      educationalActive: false,
      gradeActive: false,
      subjectActive: false,
    });
  }, [schoolValues, educationalValue, gradeValue, subjectValues]);

  // 初始執行
  useEffect(() => {
    // 取得tag列表
    getTags();

    if (editStatus === EDIT_STATUS.EDIT) {
      // 取得書本資料
      getBookById(bookId);
    } else {
      setState({ editData: null });
    }
  }, []);

  return (
    <UiBookForm>
      <UiFormTitle>{editStatus === EDIT_STATUS.CREATE ? '新增' : '編輯'}書本</UiFormTitle>
      <UiForm>
        <UiFormItem>
          <UiTextField active={displayNameActive} value={displayName} label="書本名稱" onChange={onBookNameChange} />
        </UiFormItem>
        <UiFormItem>
          <UiTextField active={bookInfoIdActive} disabled={editStatus === EDIT_STATUS.EDIT ?
            true : false} value={bookInfoId} label="書本ID" onChange={onBookIdChange} />
        </UiFormItem>
        <UiFormItem upload={true}>
          {
            bookId && <img onError={(e) => { e.target.onerror = null; e.target.src = noImg; }}
              src={`${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${bookId}/cover.jpg`} alt="" />
          }
          <UploadButton label="上傳" buttonColor="highlight" onChange={onSaveImageFile} />
        </UiFormItem>
        <UiFormItem>
          {
            SchoolYear.length > 0 && (
              <UiAutocomplete
                multiple
                active={schoolActive}
                loading={tagsLoading}
                options={SchoolYear}
                getOptionLabel={option => option.label}
                value={schoolValues}
                onChange={onSchoolYearChange}
                renderInput={params => <UiTextField {...params} label="學年度" margin="normal" />}
              />
            )
          }
        </UiFormItem>
        <UiFormItem>
          {
            EducationalSystem.length > 0 && (
              <UiAutocomplete
                multiple
                active={educationalActive}
                loading={tagsLoading}
                options={EducationalSystem}
                getOptionLabel={option => option.label}
                value={educationalValue}
                onChange={oneducationalChange}
                renderInput={(params) => <UiTextField {...params} label="學制" margin="normal" />}
              />
            )
          }
        </UiFormItem>
        <UiFormItem>
          {
            Grade.length > 0 && (
              <UiAutocomplete
                multiple
                active={gradeActive}
                loading={tagsLoading}
                options={Grade}
                getOptionLabel={option => option.label}
                value={gradeValue}
                onChange={onGradeChange}
                renderInput={(params) => <UiTextField {...params} label="年級" margin="normal" />}
              />
            )
          }
        </UiFormItem>
        <UiFormItem>
          {
            Subject.length > 0 && (
              <UiAutocomplete
                multiple
                active={subjectActive}
                loading={tagsLoading}
                options={Subject}
                getOptionLabel={option => option.label}
                value={subjectValues}
                onChange={onSubjectChange}
                renderInput={(params) => <UiTextField {...params} label="科目" margin="normal" />}
              />
            )
          }
        </UiFormItem>
      </UiForm>
      <UiBtn>
        <Button buttonColor="highlight" onClick={() => onSubmit()}>送出</Button>
        <Button onClick={() => goBack()}>回上一頁</Button>
      </UiBtn>
      <Loading isLoading={isLoding} />
    </UiBookForm>
  );
};
