const routes = [
  {
    label: '書本管理',
    path: '/books'
  },
  {
    label: '標籤管理',
    path: '/tags'
  }
];


const createRotues = env => {
  if(env === 'dev'){
     routes.push({
      label: '書本總覽',
      path: '/library'
    });
  }
  return routes;
};

export default createRotues(process.env.REACT_APP_ENV);
