import styled from 'styled-components';
import { KeyboardDatePicker as MatKeyboardDatePicker } from '@material-ui/pickers';

export const UiDateTimePicker = styled.div`
	display: block;
`;

export const UiKeyboardDateTimePicker = styled(MatKeyboardDatePicker)`
	height: 2.5rem;

	& div {
		height: 100%;
	}
`;
