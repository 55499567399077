import styled from 'styled-components';

export const UiLoginPage = styled.div`
`;

export const UiLoginBackground = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	width: 100%;
	height: 100%;
	font-family: $fontFamily;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 1;
	z-index: 1000;
	transition: opacity 0.5s;

	&.active {
		width: 0;
		height: 0;
		opacity: 0;
	}
`;

export const UiLoginBox = styled.div`
	position: relative;
	margin: auto;
	padding: 90px 70px 80px;
	width: 100%;
	max-width: 510px;
	border-radius: 0.5rem;
	z-index: 1001;
	background-image: linear-gradient(#52c4fa, #fa74b4);

	h5 {
		display: inline-block;
		margin: 0;
		margin-right: 15px;
		padding-bottom: 5px;
		width: 100%;
		font-size: 22px;
		text-align: center;
		color: #fff;
		border-bottom: 2px solid transparent;
	}
`;

export const UiLoginMessage = styled.div`
	margin: 15px auto;
	padding: 10px;
	font-size: 0.8rem;
	text-align: center;
	color: #fff;
	background-color: #f8bf01;
	border-radius: 5px;
`;

export const UiLoginBtn = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: 0.75rem 0.85rem !important;
	width: 100%;
	font-weight: 700;
	text-decoration: none;
	color: #fff;
	background-color: #d42d26;
	border-color: #d42d26;
	border-radius: 5px !important;
	transition: 0.35s;
	cursor: pointer;
	margin-bottom: 15px;

	img {
		margin-right: 10px;
		width: 24px;
	}

	&:hover {
		background-color: #f8bf01 !important;
		border-color: #fac003 !important;
	}
`;

export const UiLogIninputbox = styled.div`
	h6 {
		margin: 0;
		font-size: 1.1em;
		font-weight: 700;
		color: #fff;
		margin-bottom: 5px;
	}

	input {
		padding: 8px 5px;
		width: 100%;
		font-family: $fontFamily;
	}

	span {
		display: block;
		height: 16px;
		font-size: 0.7rem;
		font-weight: 700;
		color: #dc3545;
	}
`;

export const UiLogIninputAttion = styled.div`
	font-size: 0.8rem;
	color: #fff;
`;

export const UiLoginRemeber = styled.label`
	display: flex;
	align-items: center;
	width: 100%;
	color: #fff;
	margin-bottom: 20px;
`;

export const UiLoginLink = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 0;

	a {
		padding: 0 10px;
		text-decoration: none;
		color: #fff;

		& + a {
			border-left: 1px solid #fff;
		}
	}
`;

export const UiLogIninput = styled.div`
	margin: 15px 0;

	label {
		div {
			padding-bottom: 5px;
		}

		input {
			padding: 5px;
			border: 1px solid #ccc;
			border-radius: 5px;
		}
	}
`;
