import React from 'react';
import { Switch } from 'react-router-dom';
import RenderRoutes from 'routes/RenderRoutes';
import commonRoutes from 'routes/routesConfig/commonRoutes';
import { withTransaction } from '@elastic/apm-rum-react';



const AppRoutes = () => {
  return (
    <Switch>
      <RenderRoutes routes={commonRoutes} />
    </Switch>
  );
};


// export default AppRoutes;
export default withTransaction('AppRoutes', 'component')(AppRoutes);
