import { useSelector, useDispatch } from 'react-redux';
import { createAction, handleActions } from 'redux-actions';
import {
  getBooks as getBooksApi,
  getBookById as getBookByIdApi,
  createBook as createBookApi,
  updateBook as updateBookApi,
  deleteBook as deleteBookApi
} from 'services/api/book';
import { useAlert } from 'utils/hooks/useAlert';
import { ERROR_CODE_MESSAGE } from 'constants/index';

const GET_BOOKS = 'GET_BOOKS';
const GET_BOOK_INFO = 'GET_BOOK_INFO';
const CREATE_BOOK = 'CREATE_BOOK';
const UPDATE_BOOK = 'UPDATE_BOOK';
const DELETE_BOOK = 'DELETE_BOOK';

const initState = {
  books: {
    data: [],
    dataMap: {},
    dataInfo: null,
    total: 1
  },
  isLoding: false,
};

export const useBook = () => {
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const { books, isLoding } = useSelector(state => state.book);

  // 取得書櫃列表
  const getBooksListAction = createAction(GET_BOOKS, async params => {
    const { data, isSuccess, error } = await getBooksApi(params);
    try {
      if (!isSuccess) throw error;
      setAlert('取得列表成功', 'success');
      return { ...data };
    } catch (error) {
      setAlert('取得列表失敗', 'error');
      return {
        books: {
          data: [],
          dataMap: {},
          dataInfo: null,
          total: 1,
        }
      };
    }
  });
  const getBooksList = params => dispatch(getBooksListAction(params));

  // 取得書本
  const getBookByIdAction = createAction(GET_BOOK_INFO, async id => {
    const { data, isSuccess, error } = await getBookByIdApi(id);
    try {
      if (!isSuccess) throw error;
      if (typeof data === 'undefined') {
        return [];
      }
      return [data];
    } catch (error) {
      return [];
    }
  });
  const getBookById = id => dispatch(getBookByIdAction(id));

  // 建立書本
  const createBookAction = createAction(CREATE_BOOK, async params => {
    const { isSuccess, error } = await createBookApi(params);
    try {
      if (!isSuccess) throw error;
    } catch (error) {
      const { errorCode } = error;
      const errorMessage = ERROR_CODE_MESSAGE[errorCode] || '建立書本失敗';
      setAlert(errorMessage, 'error');
    }

    return isSuccess;
  });
  const createBook = params => dispatch(createBookAction(params));

  // 更新書櫃列表
  const updateBookAction = createAction(UPDATE_BOOK, async (id, params) => {
    const { data, isSuccess, error } = await updateBookApi(id, params);
    try {
      if (!isSuccess) throw error;
      return { ...data };
    } catch (error) {
      const { errorCode } = error;
      const errorMessage = ERROR_CODE_MESSAGE[errorCode] || '更新書本失敗';
      setAlert(errorMessage, 'error');
      return {
        books: {
          data: [],
          dataMap: {},
          dataInfo: null,
          total: 1,
        }
      };
    }
  });
  const updateBook = (id, params) => dispatch(updateBookAction(id, params));

  // 刪除書本
  const deleteBookAction = createAction(DELETE_BOOK, async id => {
    const { isSuccess, error } = await deleteBookApi(id);
    try {
      if (!isSuccess) throw error;
      if (isSuccess) return { isSuccess };
    } catch (error) {
      const { errorCode } = error;
      const errorMessage = ERROR_CODE_MESSAGE[errorCode] || '刪除書本失敗';
      setAlert(errorMessage, 'error');
      return [];
    }
  });
  const deleteBook = id => dispatch(deleteBookAction(id));

  return [
    { books, isLoding }, // state
    {
      getBooksList,
      getBookById,
      createBook,
      updateBook,
      deleteBook
    }, // eventHanlder
  ];
};


const reducer = handleActions({
  GET_BOOKS_PENDING: state => ({
    ...state,
    isLoding: true
  }),
  GET_BOOKS_FULFILLED: (state, action) => {
    const { books, total } = action.payload;
    return {
      ...state,
      books: { ...state.books, data: books, total, },
      isLoding: false,
    };
  },
  GET_BOOKS_REJECTED: state => ({
    ...state,
    books: {
      data: [],
      dataMap: {},
      dataInfo: null,
      total: 1
    },
    isLoding: false
  }),
  GET_BOOK_INFO_PENDING: state => ({
    ...state,
    isLoding: true
  }),
  GET_BOOK_INFO_FULFILLED: (state, action) => {
    return {
      ...state,
      books: { ...state.books, data: action.payload, total: 1 },
      isLoding: false,
    };
  },
  GET_BOOK_INFO_REJECTED: state => ({
    ...state,
    books: {
      data: [],
      dataMap: {},
      dataInfo: null,
      total: 1
    },
    isLoding: false
  }),
  CREATE_BOOK_PENDING: state => ({
    ...state,
    isLoding: true
  }),
  CREATE_BOOK_FULFILLED: state => {
    return {
      ...state,
      isLoding: false,
    };
  },
  CREATE_BOOK_REJECTED: state => ({
    ...state,
    books: {
      data: [],
      dataMap: {},
      dataInfo: null,
      total: 1
    },
    isLoding: false
  }),
  DELETE_BOOK_FULFILLED: state => ({
    ...state
  })
  // UPDATE_BOOK_PENDING: state => ({
  //   ...state,
  //   isLoding: true
  // }),
  // UPDATE_BOOK_FULFILLED: state => ({
  //   ...state,
  //   isLoding: false,
  // }),
  // UPDATE_BOOK_REJECTED: state => ({
  //   ...state,
  //   isLoding: false
  // }),
}, initState);

export default reducer;
