import React from 'react';
import { Library } from 'components';
import { UiLibraryPage } from './LibraryPage.style';


/**
 * 書籍總覽
 */

export const LibraryPage = () => {
  return (
    <UiLibraryPage>
      <Library />
    </UiLibraryPage>
  );
};
