import React from 'react';
import PropTypes from 'prop-types';

import { Modal, DropzoneUploader } from 'components';
import { useSetState } from 'utils/hooks/useSetState';

import { UiClickableUpload, UiUploadFileName } from './ClickableUpload.style';


/**
 * 可使被套用的組件點擊後彈出上傳視窗
 */

export const ClickableUpload = ({
  children,
  onUploaded,
  isShowFileList = false,
  fileUrl = '',
}) => {
  const [{ isOpen, file }, setState] = useSetState({
    isOpen: false,
    file: '',
  });

  const deleteFile = () => {
    setState({ file: null });
  };

  const actionButtons = [
    {
      text: '確認',
      func: () => onUploaded(file)
    },
    {
      text: '取消',
      color: 'highlight',
      func: deleteFile
    }
  ];

  const onClickHandler = () => {
    setState({ isOpen: true });
  };

  const getModalStateHandler = state => {
    setState({ isOpen: state });
  };

  const onUploadeHandler = file => {
    setState({ file: file[0] });
  };

  const contentComponent = <DropzoneUploader onChange={onUploadeHandler} filesLimit={1} />;


  return (
    <>
      <UiClickableUpload onClick={onClickHandler}>
        {children}
      </UiClickableUpload>
      {
        isShowFileList && file && (
          <UiUploadFileName>
            {file.name}
          </UiUploadFileName>
        )
      }
      {
        (fileUrl !== '' && !file) &&
        <UiUploadFileName>
          <a href={fileUrl} target='_blank' rel="noopener noreferrer">查看附件</a>
        </UiUploadFileName>
      }
      <Modal
        autoClose
        isIconShow={false}
        isOpen={isOpen}
        content={contentComponent}
        buttons={actionButtons}
        getModalState={getModalStateHandler}
      />
    </>

  );
};

ClickableUpload.propTypes = {
  children: PropTypes.node,
  onUploaded: PropTypes.func,
  isShowFileList: PropTypes.bool,
  fileUrl: PropTypes.string,
};


