import { Api } from 'services/api';
import { stringify } from 'query-string';

const BASE_PATH = '/admin/books';

/**
 * 取得 所有book資訊
 *
 * @param {string} displayName
 * @param {array}  tags
 * @param {number} publishedAt
 * @param {number} unpublishedAt
 * @param {string} orderBy
 * @param {number} skip
 * @param {number} limit
 *
 * @returns {object} all book list
 */
export const getBooks = async params => {
  const { displayName, tags, publishedAt, unpublishedAt, page } = params;

  const LIMIT = 10;
  const queryParams = {
    limit: LIMIT,
    skip: (page - 1) * LIMIT
  };

  if (displayName) {
    queryParams.displayName = displayName;
  }

  if (tags) {
    queryParams.tags = tags;
  }

  if (publishedAt) {
    queryParams.publishedAt = publishedAt;
  }

  if (unpublishedAt) {
    queryParams.unpublishedAt = unpublishedAt;
  }

  const querystring = stringify({...queryParams, from: process.env.REACT_APP_TARGET}, { arrayFormat: 'bracket' });
  const response = await Api.get(`${BASE_PATH}?${querystring}`);
  return response;
};

/**
 * 取得 單一book資訊
 *
 * @param {string} id
 *
 * @returns {object} book information
 */
export const getBookById = async id => {
  const queryParams = {
    from: process.env.REACT_APP_TARGET
  };
  const querystring = stringify(queryParams, { arrayFormat: 'bracket' });
  const response = await Api.get(`/books/${id}?${querystring}`);
  return response;
};

/**
 * 新增 單一book資訊
 *
 * @param {string} bookId
 * @param {string} displayName
 * @param {boolean} isPublish
 * @param {array} tags
 *
 * @returns {object} book information
 */
export const createBook = async params => {
  const response = await Api.post(BASE_PATH, params);
  return response;
};

/**
 * 更新 單一book資訊
 *
 * @param {string} displayName
 * @param {boolean} isPublish
 * @param {array} tags
 *
 * @returns {object} book information
 */
export const updateBook = async (id, params = {}) => {
  const response = await Api.put(`${BASE_PATH}/${id}`, params);
  return response;
};

/**
 * 刪除 book
 *
 * @param {string} id
 *
 */
export const deleteBook = async id => {
  const response = await Api.delete(`${BASE_PATH}/${id}`, id);
  return response;
};
