import styled from 'styled-components';

export const UiAppLayout = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

export const UiContainer = styled.div`
	display: flex;
	width: 100%;
`;

export const UiMenuWrapper = styled.div`
	flex: 1.5;
`;
export const UiContentWrapper = styled.div`
	flex: 8;
	padding: 0 10px;
`;
