import { EDIT_STATUS } from 'constants/index';
import {
  LoginPage,
  TagListPage,
  TagFormPage,
  BookListPage,
  BookFormPage,
  LibraryPage,
  AppLayout
} from 'components';


const routes = [
  {
    path: '/books',
    component: AppLayout,
    routes: [
      {
        path: '/books',
        component: BookListPage,
        exact: true,
      },
      {
        path: `/books/bookform/${EDIT_STATUS.CREATE}`,
        component: BookFormPage,
        exact: true,
        editStatus: EDIT_STATUS.CREATE
      },
      {
        path: `/books/bookform/${EDIT_STATUS.EDIT}/:bookId`,
        component: BookFormPage,
        exact: true,
        editStatus: EDIT_STATUS.EDIT
      },
    ]
  },
  {
    path: '/tags',
    component: AppLayout,
    routes: [
      {
        path: '/tags',
        component: TagListPage,
        exact: true,
      },
      {
        path: `/tags/tagform/${EDIT_STATUS.CREATE}`,
        component: TagFormPage,
        exact: true,
        editStatus: EDIT_STATUS.CREATE
      },
      {
        path: `/tags/tagform/${EDIT_STATUS.EDIT}/:tagId`,
        component: TagFormPage,
        exact: true,
        editStatus: EDIT_STATUS.EDIT
      },
    ]
  },
  {
    path: '/login',
    component: AppLayout,
    routes: [
      {
        path: '/login',
        component: LoginPage,
        exact: true
      }
    ]
  }
];


const createRotues = env => {
  if(env === 'dev'){
     routes.push({
      path: '/library',
      component: AppLayout,
      routes: [
        {
          path: '/library',
          component: LibraryPage,
          exact: true
        }
      ]
    });
  }

  return routes;
};



export default createRotues(process.env.REACT_APP_ENV);
