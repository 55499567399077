import styled from 'styled-components';

export const UiPdfUploader = styled.div`
	display: block;
	padding: 0;

	& > button {
		margin: 20px auto;
	}
`;

export const UiTopContent = styled.div`
	display: flex;
	margin: 10px 0;

	& > label {
		margin-left: 5px;
	}
`;
