import styled from 'styled-components';
import {
  Dialog as MatDialog,
  DialogContent as MatDialogContent,
  DialogContentText as MatDialogContentText,
  DialogTitle as MatDialogTitle,
  DialogActions as MatDialogActions
} from '@material-ui/core';

export const UiModal = styled(MatDialog)`
	& > div > div {
		padding: 1.25rem;
		max-width: 100%;
		border-radius: 0.3125rem;
	}
`;

export const UiMatDialogTitle = styled(MatDialogTitle)`
	font-weight: bold;
	color: #fff;
	background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const UiMatDialogContent = styled(MatDialogContent)`
	color: #000;
`;
export const UiMatDialogContentText = styled(MatDialogContentText)`
	font-size: 1.5rem;
	text-align: center;
	color: #595959;
`;

export const UiMatDialogActions = styled(MatDialogActions)`
	justify-content: center;
	margin-top: 1.25rem;

	button {
		padding: 0.625rem 2rem;
		font-size: 1rem;
		font-weight: 500;
		border: 0;
		border-radius: 0.25rem;
	}
`;
