import React,{ useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  MenuItem as MatMenuItem,
  FormControl as MatFormControl,
  InputLabel as MatInputLabel
} from '@material-ui/core';

import { useTag } from 'store/tag';
import { EDIT_STATUS, TAGS_LABEL_MAP, SOURCE_TYPE } from 'constants/index';
import { Button } from 'components';
import {
  UiForm,
  UiFormTitle,
  UiFormItem,
  UiSelect,
  UiTextField
} from 'styles';
import { UiTagForm, UiButtonGroup } from './TagForm.style';


/**
 * 新增、編輯 tag 表單
 */

export const TagForm = ({ editStatus }) => {
  const isEditing = editStatus === EDIT_STATUS.EDIT;
  const history = useHistory();
  const { tagId } = useParams();

  const [{ tags:{ idMap, dataMap } }, { updateTag, createTag }] = useTag();

  const submitAction = ({ label, category, sort }) => isEditing ?
    updateTag(tagId, { label, category, sort: parseInt(sort) }) :
    createTag({ label, category });

  const defaultLabel = idMap?.[tagId]?.label || '';
  const defaultCategory = idMap?.[tagId]?.category || '';
  const defaultSort = idMap?.[tagId]?.sort || '';
  const [label,setLabel] = useState(defaultLabel);
  const [category,setCategory]  = useState(defaultCategory);
  const [sort,setSort]  = useState(defaultSort);

  const source = idMap?.[tagId]?.source || '';

  useEffect(() => {
    if(isEditing && !tagId) {
      history.push('/tags');
    }
  },[editStatus,tagId]);

  const labelChangeHandler = e => {
    const { value } = e.target;
    setLabel(value);
  };

  const categoryChangeHandler = e => {
    const { value } = e.target;
    setCategory(value);
  };

  const sortChangeHandler = e => {
    const { value } = e.target;
    setSort(value);
  };

  const submitHandler = () => {
    const submitData = {
      category,
      label,
      sort
    };
    submitAction(submitData);
  };

  const goBack = () => {
    history.push('/tags');
  };

  return (
    <UiTagForm>
      <UiForm>
        <UiFormTitle>{isEditing ? '編輯' : '新增'}標籤</UiFormTitle>
        <UiFormItem>
          <MatFormControl>
          <MatInputLabel>標籤類別</MatInputLabel>
          <UiSelect
            value={category}
            onChange={categoryChangeHandler}
            disabled={source === SOURCE_TYPE.PRESET}
          >
            {
              Object.keys(dataMap).map(key=>(
                <MatMenuItem value={key} key={key}>{TAGS_LABEL_MAP[key]}</MatMenuItem>
              ))
            }
          </UiSelect>
          </MatFormControl>
        </UiFormItem>
        <UiFormItem>
          <UiTextField 
            label="標籤名稱" 
            variant='outlined' 
            disabled={source === SOURCE_TYPE.PRESET} 
            value={label} 
            onChange={labelChangeHandler} 
          />
        </UiFormItem>
        {
          isEditing && (
            <UiFormItem>
              <UiTextField label="排序" variant='outlined' value={sort} onChange={sortChangeHandler} />
            </UiFormItem>
          )
        }
      </UiForm>
      <UiButtonGroup>
        <Button buttonColor="highlight" onClick={submitHandler}>送出</Button>
        <Button onClick={goBack}>回上一頁</Button>
      </UiButtonGroup>
    </UiTagForm>
  );
};

TagForm.propTypes = {
  editStatus: String
};


