import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { EDIT_STATUS, TAGS_LABEL_MAP, SOURCE_TYPE } from 'constants/index';
import { useSetState } from 'utils/hooks/useSetState';
import { useTag } from 'store/tag';
import { Button, Modal } from 'components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  UiTable,
  UiTableTitle,
  UiTableItem,
  UiTableContentItem,
  UiTextField,
} from 'styles';
import {
  UiTagList,
  Uioption,
  UiDeleteVerifyWrap,
  UiVerifyErrorMsg,
  UiActions,
 } from './TagList.style';


/**
 * 標籤列表
 */



// eslint-disable-next-line react/prop-types
const TabPanel = ({ children, value, index }) => {
  return (
    <div
      hidden={value !== index}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
};

export const TagList = () => {
  const history = useHistory();
  const [{ tags: { dataMap }}, { getTags, deleteTag }] = useTag();
  const tags = Object.values(dataMap);

  const [tabValue, setTabValue] = useState(0);

  const [{ modalDeleteState, deleteVerifyInput },setState] = useSetState({
    modalDeleteState: {
      isOpen: false,
      title: '',
      text: '',
      editData: {},
    },
    deleteVerifyInput:{
      inputContent: '',
      isError: false
    }
   });

  useEffect(()=>{
    getTags();
  },[]);


  const tabChangeHandler = (event, newValue) => {
    setTabValue(newValue);
  };

  const editButtonClickHandler = tagId => {
    history.push(`/tags/tagform/${EDIT_STATUS.EDIT}/${tagId}`);
  };

  const removeButtonClickHandler = tagId => {
    setState({
      modalDeleteState: {
        isOpen: true,
        title: '您確定要刪除書本嗎？',
        text: '刪除後，會將書本從後台移除。若確定要刪除，請在以下欄位中輸入「刪除」並確認',
        editData: tagId
      },
    });
  };

  const goCreatePage = () => {
    history.push(`/tags/tagform/${EDIT_STATUS.CREATE}`);
  };

  const modalDeleteButtons = [
    {
      text: '確認',
      func: async () => {
        if (deleteVerifyInput.inputContent === '刪除') {
          const data = await deleteTag(modalDeleteState.editData);
          if (data.value.isSuccess) {
            setState({
              ...modalDeleteState,
              modalDeleteState: { isOpen: false }
            });
          }
        } else {
          setState({
            ...deleteVerifyInput,
            deleteVerifyInput: {
              isError: true
            }
          });
        }
      }
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => {
        setState({
          ...modalDeleteState,
          modalDeleteState: { isOpen: false }
        });
      }
    }
  ];

  const getModalDeleteStateHandler = state => {
    setState({
      ...modalDeleteState,
      modalDeleteState: { isOpen: state }
    });
  };

  const verifyDeleteInput = e => {
    setState({
      ...deleteVerifyInput,
      deleteVerifyInput: {
        inputContent: e.target.value
      }
    });
  };

  return (
    <UiTagList>
        <Uioption>
          <Tabs value={tabValue} onChange={tabChangeHandler}>
            {
              Object.keys(dataMap).map(key => <Tab key={key} label={TAGS_LABEL_MAP[key]} />)
            }
          </Tabs>
            <Button onClick={goCreatePage}>新增</Button>
        </Uioption>
        {
          Object.keys(dataMap).map((key,index) => (
            <TabPanel key={key} index={index} value={tabValue}>
              <UiTable>
                <UiTableTitle>
                <UiTableItem>排序</UiTableItem>
                  <UiTableItem>標籤名稱</UiTableItem>
                  <UiTableItem>來源</UiTableItem>
                  <UiTableItem>操作</UiTableItem>
                </UiTableTitle>
                {
                  tags[tabValue].map(item => (
                    <UiTableContentItem key={item.tagId}>
                      <UiTableItem>{item.sort}</UiTableItem>
                      <UiTableItem>{item.label}</UiTableItem>
                      <UiTableItem>{item.source === SOURCE_TYPE.PRESET ? '資源平台' : '自訂'}</UiTableItem>
                      <UiTableItem>
                        <UiActions>
                          <Button
                            buttonColor="highlight"
                            onClick={() => editButtonClickHandler(item.tagId)}
                          >編輯</Button>
                          {
                            item.source === SOURCE_TYPE.PRESET ? null : (
                              <Button
                                buttonColor="delete"
                                onClick={() => removeButtonClickHandler(item.tagId)}
                              >刪除</Button>
                            )
                          }
                        </UiActions>
                      </UiTableItem>
                    </UiTableContentItem>
                  ))
                }

              </UiTable>
            </TabPanel>
          ))
        }
        <Modal
          isOpen={modalDeleteState.isOpen}
          title={modalDeleteState.title}
          text={modalDeleteState.text}
          buttons={modalDeleteButtons}
          getModalState={getModalDeleteStateHandler}>
          <UiDeleteVerifyWrap>
            <UiTextField variant='outlined' onChange={verifyDeleteInput}></UiTextField>
            {deleteVerifyInput.isError ? <UiVerifyErrorMsg>請輸入正確內容</UiVerifyErrorMsg> : null}
        </UiDeleteVerifyWrap>
      </Modal>
    </UiTagList>
  );
};

TagList.propTypes = {

};


