import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import {
  UiLoginPage,
  UiLoginBackground,
  UiLoginBox,
  UiLoginMessage,
  UiLoginBtn,
  UiLogIninputbox,
  UiLogIninputAttion,
  UiLoginRemeber,
  UiLoginLink,
  UiLogIninput
} from './LoginPage.style';
import { useUser } from 'store/user';

/**
 * 登入頁面
 */

export const LoginPage = () => {
  const history = useHistory();
  const getRememberLocal = localStorage.getItem('remember');
  const [{ isAuth }, { userLogin }] = useUser();
  const [rememberMe, setRememberMe] = useState(true);
  const [errormessage, seterrmessage] = useState('');
  const [UserParams, setUserParams] = useSetState({
    Account: getRememberLocal || '',
    Password: ''
  });
  const [remark, setRemark] = useSetState({
    Account: '',
    Password: ''
  });

  useEffect(()=>{
    isAuth && history.push('/books');
  },[isAuth]);

  const inputList = [
    {
      Title: '用戶名稱',
      Attion: '',
      Input: {
        Type: 'text',
        Name: 'Account',
        Value: UserParams.Account,
        Placeholder: '請輸入您的用戶名稱'
      },
      Remark: remark.Account
    },
    {
      Title: '密碼',
      Attion: '*大小寫視為不同',
      Input: {
        Type: 'password',
        Name: 'Password',
        Value: UserParams.Password,
        Placeholder: '請輸入您的用戶密碼'
      },
      Remark: remark.Password
    }
  ];

  const updateUserParams = e => {
    setUserParams({ [e.target.name]: e.target.value });
  };

  const Submit = useCallback(async () => {
    setRemark({
      Account: UserParams.Account ? '' : '請輸入帳號或正確的格式',
      Password: UserParams.Password ? '' : '請輸入密碼'
    });

    if (UserParams.Account === '' || UserParams.Password === '') return false;

    rememberMe ? localStorage.setItem('remember', UserParams.Account) : localStorage.setItem('remember', '');

    const data = await userLogin({ username: UserParams.Account, password: UserParams.Password });


    if (data.value.isAuth) {
      seterrmessage('');
      history.push('/books');
    } else {
      seterrmessage(data.value.message);
    }

  }, [UserParams.Account, UserParams.Password, rememberMe, setRemark, setUserParams]);

  const handleKeyPress = (event) => {
    event.key === 'Enter' && Submit();
  };

  return (<UiLoginPage>
    <UiLoginBackground>
      <UiLoginBox>
        <h5>登入</h5>
        {errormessage ?
          <UiLoginMessage>{errormessage}</UiLoginMessage> : null
        }
        {
          inputList.map((item) => {
            return (
              <UiLogIninputbox key={item.Input.Name}>
                <h6>{item.Title}</h6>
                <UiLogIninputAttion>{item.Attion}</UiLogIninputAttion>
                <UiLogIninput>
                  <label>
                    <input type={item.Input.Type} placeholder={item.Input.Placeholder}
                      value={item.Input.Value}
                      name={item.Input.Name}
                      onChange={updateUserParams}
                      onKeyPress={e => { handleKeyPress(e); }} />
                  </label>
                </UiLogIninput>
                {item.Remark ? <span>{item.Remark}</span> : ''}
              </UiLogIninputbox>
            );
          })
        }
        <UiLoginRemeber>
          <input
            type="checkbox"
            defaultChecked
            onClick={() => { setRememberMe(!rememberMe); }}
          />
          <span>記住帳號</span>
        </UiLoginRemeber>
        <UiLoginBtn onClick={() => Submit()}>
          <span>登入</span>
        </UiLoginBtn>
        <hr />
        <UiLoginLink>
          <a href="https://oneclub.oneclass.com.tw/forgetpassword/" target="blank">忘記密碼</a>
          <a href="https://oneclub.oneclass.com.tw/signup/" target="blank">註冊帳號</a>
        </UiLoginLink>
      </UiLoginBox>
    </UiLoginBackground>
  </UiLoginPage>);
};

