import React, { useEffect } from 'react';
import { Switch, Redirect, useHistory } from 'react-router-dom';
import { useUser } from 'store/user';
import { ApmRoute } from '@elastic/apm-rum-react';

const useAuthGuard = () => {
  const history = useHistory();
  const [{ isAuth }, { checkUserLoginStatus }] = useUser();

  useEffect(() => {
    const checkLoginStatus = async () => {
      if(isAuth) return;

      const status = await checkUserLoginStatus();
      if(!status.value.isAuth || !isAuth){
        history.push('/login');
      }

    };
    checkLoginStatus();
  }, []);

  return isAuth;
};

const RouteWithSubRoutes = route => (
    <ApmRoute
      path={route.path}
      exact={route.exact}
      render={props =>
          <route.component {...props} {...route} routes={route.routes} />
      }
    />
  );

// eslint-disable-next-line react/prop-types
const RenderRoutes = ({ routes = [] }) => {
  const isAuth = useAuthGuard();
  return (
    <Switch>
      {
        routes.filter(route => {
          if(isAuth || route.path.match('login')){
            return true;
          }
        }).map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))
      }
      <ApmRoute exact path="/">
        <Redirect to="/login" />
      </ApmRoute>
      <ApmRoute path="*">
        <Redirect to="/" />
      </ApmRoute>
    </Switch>
  );
};


export default RenderRoutes;
