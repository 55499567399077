import React from 'react';
import PropTypes from 'prop-types';
import RenderRoutes from 'routes/RenderRoutes';
import { Header, SideMenu } from 'components';
import {
  UiAppLayout,
  UiContainer,
  UiMenuWrapper,
  UiContentWrapper
 } from './AppLayout.style';


/**
 * App layout
 */

export const AppLayout = ({
  routes
}) => {
  return (
    <UiAppLayout>
      <Header />
      <UiContainer>
        <UiMenuWrapper>
          <SideMenu/>
        </UiMenuWrapper>
        <UiContentWrapper>
          <RenderRoutes routes={routes} />
        </UiContentWrapper>
      </UiContainer>
    </UiAppLayout>
  );
};

AppLayout.propTypes = {
  routes: PropTypes.array,
};


