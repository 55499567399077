import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddlerware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';

import middlewares from './middlewares';
import reducers from './reducers';


const isDev = process.env.NODE_ENV !== 'production';
const isBrowser = typeof window !== 'undefined';



const applyMiddlewares = [...middlewares, thunkMiddlerware, promiseMiddleware];

// development tools
const composeEnhancers =
  isBrowser && isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

if (isDev) {
  const { logger } = require('redux-logger');
  applyMiddlewares.push(logger);
}

const enhancers = [applyMiddleware(...applyMiddlewares)];


const configureStore = initialState => {
  const store = createStore(reducers, initialState, composeEnhancers(...enhancers));
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
};

export default configureStore;
