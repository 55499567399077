import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const UiSideMenu = styled.div`
	display: flex;
	flex-direction: column;
`;

export const UiNavLink = styled(NavLink)`
	display: inline-block;
	padding: 10px;
	font-size: 20px;
	text-align: center;
	text-decoration: none;
	color: #121212;
	border: 1px solid #ccc;

	&.active {
		color: steelblue;
	}
`;
