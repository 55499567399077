import React from 'react';
import {
  UiHeader,
  UiContainer,
  UiLogo,
  UiText,
  UiUser
} from './Header.style';
import { useUser } from 'store/user';
import { LoginBtn } from 'components';

/**
 * 頁首
 */

export const Header = () => {
  const [{ isAuth }] = useUser();

  return (
    <UiHeader>
      <UiContainer>
        <UiLogo>
          <img src="/img/logo.png" alt="onebook" />
          <UiText>書城後台</UiText>
        </UiLogo>
        {isAuth ? <UiUser>
          <LoginBtn />
        </UiUser> : null}
      </UiContainer>
    </UiHeader>
  );
};
