import React from 'react';
import { UiBookFormPage } from './BookFormPage.style';
import { BookForm } from 'components'


/**
 * 書城表單頁面
 */

export const BookFormPage = props => {
  return (
    <UiBookFormPage><BookForm {...props} /></UiBookFormPage>
  );
};

