import MatButton from '@material-ui/core/Button';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

export const UiButton = styled(MatButton)`
position: relative;
display: flex;
padding: 5px 10px;
height: 31px;
font-size:
	${(
		{ theme,
		sizeType }
	) => theme.text[sizeType]};
color:
	${(
		{ theme,
		textColor }
	) => textColor ?
	textColor
	: '#fff'};

background:
	${(
		{ theme,
		buttonColor,
		loading }
	) => loading === true ?
	theme.btn['disable'] :
	theme.btn[buttonColor]};
border-radius: 5px;
box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.3);
cursor:
	${(
		{ buttonColor,
		loading }
	) =>
	(buttonColor === 'disable' || loading === true) ? 'not-allowed' : 'pointer'};

&:hover {
	background:
		${(
			{ theme,
			buttonColor,
			loading }
		) => loading === true ?
		theme.btn['disable'] :
		theme.btn[buttonColor + 'hover']};
}

+ button {
	margin-left: 5px;
}
`;
export const UiCircularProgress = styled(CircularProgress)`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	color: #fff;
`;
export const UiInlineFlex = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
`;
