import React from 'react';
import { TagForm } from 'components';
import { UiTagFormPage } from './TagFormPage.style';


/**
 * 新增、編輯 tag 表單頁面
 */

export const TagFormPage = (props) => {
  return (
    <UiTagFormPage>
      <TagForm {...props}/>
    </UiTagFormPage>
  );
};

