import { init } from '@elastic/apm-rum';

export const apm = init({

  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: 'bookStore-client',

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: 'https://11eddc2231524b189368f9654aef36c3.apm.asia-east1.gcp.elastic-cloud.com:443',

  // Set the service version (required for source map feature)
  serviceVersion: process.env.REACT_APP_VERSION || '0.0.1',

  // Set the service environment
  environment: process.env.REACT_APP_ENV || 'dev',
  // active: process.env.NODE_ENV === 'production',
  distributedTracing: true,
  distributedTracingOrigins: [
    'https://asia-east2-bookstore-dev-a9958.cloudfunctions.net',
    /cloudfunctions/g,
    /oneclass/g
  ],
  propagateTracestate: true
});
