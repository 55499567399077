import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createAction, handleActions } from 'redux-actions';
import { Roles } from 'constants/index';
import { useAlert } from 'utils/hooks/useAlert';
import { login, logout, checkLogin, getProfile } from 'services/api/login';

const USER_LOGIN = 'USER_LOGIN';
const CHECK_LOGIN = 'CHECK_LOGIN';
const CHECK_LOGOUT = 'CHECK_LOGOUT';
const GET_USERPROFILE = 'GET_USERPROFILE';

const initState = {
  userId: '',
  name: '',
  isAuth: false,
  token: null,
};

export const useUser = () => {
  const histroy = useHistory();
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const { userId, name, isAuth } = useSelector(state => state.user);

  // 檢查登入狀態
  const checkUserLoginStatusAction = createAction(CHECK_LOGIN, async () => {
    const token = await checkLogin();
    const profile = token ? await getUserProfile() : {};
    return profile;
  });

  const checkUserLoginStatus = () => dispatch(checkUserLoginStatusAction());

  //  user 登入
  const userLoginAction = createAction(USER_LOGIN, async params => {
    const { code: isSuccess, error } = await login(params);

    try {
      if (isSuccess === 'SUCCESS') {
        const { value: { isAuth } } = await getUserProfile();
        isAuth ? setAlert('登入成功', 'success') : setAlert('請使用編輯權限', 'error');
        return { isAuth };
      } else {
        setAlert('登入失敗', 'error');
        return { isAuth: false, message: error };
      }
    } catch (error) {
      const { errorCode } = error;
      setAlert('登入失敗', 'error', errorCode);
      return errorCode;
    }
  });

  const userLogin = (username, password) => dispatch(userLoginAction(username, password));

  // 取得 user profile
  const getUserProfileAction = createAction(GET_USERPROFILE, async () => {

    const error = {
      msg: 'error'
    };

    try {
      if(!name && !userId){
        const { profile, isSuccess } = await getProfile();
        if (!isSuccess) throw error;
        return profile;
      } else {
        const { profile: { role } } = await getProfile();
        return {
          name,
          userId,
          isAuth: role === Roles.Editor
        };
      }


    } catch (error) {
      const { errorCode } = error;
      setAlert('登入失敗', 'error', errorCode);
      return errorCode;
    }
  });

  const getUserProfile = () => dispatch(getUserProfileAction());

  //  user 登出
  const userLogoutAction = createAction(CHECK_LOGOUT, async () => {
    await logout();
    histroy.push('/login');
  });

  const userLogout = () => dispatch(userLogoutAction());

  return [
    {
      name,
      isAuth,
    }, // state
    {
      userLogin,
      userLogout,
      checkUserLoginStatus
    }, // eventHanlder
  ];
};

const reducer = handleActions({
  CHECK_LOGIN_FULFILLED: (state, action) => {
    const { role } = action.payload;
    return {
      ...state,
      isAuth: role === Roles.Editor
    };
  },
  GET_USERPROFILE_FULFILLED: (state, action) => {
    const { userId, name, role } = action.payload;
    return {
      ...state,
      userId: userId,
      name: name,
      isAuth: role === Roles.Editor,
    };
  },
  GET_USERPROFILE_REJECTED: () => {
    return {
      userId: '',
      name: '',
      isAuth: false,
      token: null
    };
  },
  USER_LOGIN_FULFILLED: (state, action) => {
    const { isAuth } = action.payload;
    return {
      ...state,
      isAuth,
    };
  },
  USER_LOGIN_REJECTED: () => {
    return {
      userId: '',
      name: '',
      isAuth: false,
      token: null
    };
  },
  CHECK_LOGOUT_FULFILLED: () => {
    return {
      userId: '',
      name: '',
      isAuth: false,
      token: null
    };
  }
}, initState);

export default reducer;
