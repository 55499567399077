import { useCallback, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { useSetState } from 'utils/hooks/useSetState';

const bookstoreFirebaseConfig = {
  apiKey: process.env.REACT_APP_BOOKSTORE_API_KEY,
  authDomain: process.env.REACT_APP_BOOKSTORE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_BOOKSTORE_DATABASE_URL,
  projectId: process.env.REACT_APP_BOOKSTORE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_BOOKSTORE_STORAGE_BUCKET
};
export const useFirebase = (config = bookstoreFirebaseConfig) => {
  const [{ storage }, setState] = useSetState({
    storage: null
  });
  const initialize = useCallback(() => {
    let app;
    if (firebase.apps.length <= 0 || !firebase.app('nani-DocConvert')) {
      app = firebase.initializeApp(config, 'nani-DocConvert');
    } else {
      app = firebase.app('nani-DocConvert');
    }
    app.auth().signInAnonymously()
      .then(() => {
        setState({ storage: app.storage() });
      })
      .catch(err => console.error('sign in anonymously error', err));
  }, [config, setState]);

  useEffect(() => {
    if (!config) return;
    initialize();
  }, [config, initialize]);
  return { storage };
};
