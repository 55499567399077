import React from 'react';
import { TagList } from 'components';
import { UiTagListPage } from './TagListPage.style';


/**
 * 標籤列表頁面
 */

export const TagListPage = () => {
  return (
      <UiTagListPage>
        <TagList />
      </UiTagListPage>
  );
};

TagListPage.propTypes = {

};


