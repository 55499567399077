import styled from 'styled-components';

export const UiTagForm = styled.div`
	width: 80%;
`;

export const UiButtonGroup = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
