import styled from 'styled-components';
import {
  Select as MatSelect,
  TextField as MatTextField,
} from '@material-ui/core';

export const UiTable = styled.div`
	margin: 20px 0;
`;

export const UiTableTitle = styled.div`
	display: flex;
	justify-content: center;
	border-top: 1px solid #aaa;
	border-right: 1px solid #aaa;
	border-left: 1px solid #aaa;
`;

export const UiTableContent = styled.div`
	border-right: 1px solid #aaa;
	border-left: 1px solid #aaa;
	border-bottom: 1px solid #aaa;
`;
export const UiTableContentItem = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: center;
`;

export const UiTableItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding: 10px 5px;
	box-sizing: border-box;
	border: 1px solid #aaa;
	flex: ${({ flex }) => flex ? flex : 1};
	word-break: break-all;

	&:last-child {
		justify-content: space-evenly;
		min-width: 280px;
	}

	& img {
		width: 90%;
	}
`;


export const UiForm = styled.div`
	display: flex;
	flex-direction: column;
`;

export const UiFormTitle = styled.div`
	margin: 10px 0;
`;

export const UiFormItem = styled.div`
	display: ${({ upload }) => upload ? 'block' : 'flex'};
	margin: 20px 0;

	& > div {
		width: ${({ upload }) => upload ? 'auto' : '100%'};
	}

	& > img {
		display: block;
		width: 10%;
		margin-bottom: 1rem;
	}
`;



export const UiSelect = styled(MatSelect)`
width: 200px;
height: 2.5rem;

& > div {
	padding: 7.5px 14px;
}
`;

export const UiTextField = styled(MatTextField)`
	height: 2.5rem;

	& label {
		color: ${({ active }) => active ? '#f00' : 'rgba(0, 0, 0, 0.54)'};
	}

	& div {
		height: 100%;
	}
`;


export const UiDeleteVerifyWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const UiVerifyErrorMsg = styled.div`
	margin-left: 10px;
	color: #f00;
`;
