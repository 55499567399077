import styled from 'styled-components';
import { Autocomplete as MatAutocomplete } from '@material-ui/lab';

export const UiBookForm = styled.div`
	width: 80%;
`;

export const UiBtn = styled.div`
	display: flex;
	justify-content: center;

	& > button {
		margin: 0 10px;
	}
`;

export const UiAutocomplete = styled(MatAutocomplete)`
	& label {
		color: ${({ active }) => active ? '#f00' : 'rgba(0, 0, 0, 0.54)'};
	}
`;
