import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  UiLoginBtn,
  UiDropdownBox,
  UiDropdownMenu,
  UiDropdownItem,
  UiLoginInfo
} from './LoginBtn.style';
import { useUser } from 'store/user';

/**
 * 在這邊描述這個組件
 */

export const LoginBtn = () => {
  const history = useHistory();
  const [{ name, isAuth }, { userLogout }] = useUser();
  const [loginBtnState, setLoginBtnStatus] = useState(false);

  const logout = async () => {
    await userLogout();
    history.push('/login');
  };

  return (<UiLoginBtn>
    <UiDropdownBox>
      <UiLoginInfo onClick={() => setLoginBtnStatus(!loginBtnState)}>
        <span className={isAuth ? 'show' : null}>{name}</span>
        <span className={'icon'}>▼</span>
      </UiLoginInfo>
      <UiDropdownMenu className={loginBtnState ? 'active' : null}>
        <UiDropdownItem onClick={() => {
          window.updatePersonalDataNaniOneClass();
        }}>修改資料</UiDropdownItem>
        <UiDropdownItem onClick={logout}>登出</UiDropdownItem>
      </UiDropdownMenu>
    </UiDropdownBox>
  </UiLoginBtn>);
};


