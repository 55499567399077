import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import {
  FormControlLabel as MatFormControlLabel,
  Checkbox as MatCheckbox,
  InputLabel as MatInputLabel,
  Select as MatSelect,
  MenuItem as MatMenuItem,
  FormControl as MatFormControl
} from '@material-ui/core';
import { useSetState } from 'utils/hooks/useSetState';
import { DropzoneUploader } from 'components';
import { UiPdfUploader, UiTopContent } from './PdfUploader.style';

/**
 * pdf 上傳組件
 */

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const PdfUploader = ({ getFileHandler }) => {
  const classes = useStyles();
  const [{
    type,
    compressImg,
    file,
  }, setState] = useSetState({
    type: 'svg_realtext',
    compressImg: true,
    file: []
  });
  const onFileChange = (key, value) => {
    setState({
      [key]: value
    });
  };

  useEffect(() => {
    getFileHandler({
      type,
      compressImg,
      file
    });
  }, [type, compressImg, file]);

  return (
    <UiPdfUploader>
      <UiTopContent>
        <MatFormControl variant="filled" className={classes.formControl}>
          <MatInputLabel id="simple-select-label">類型</MatInputLabel>
          <MatSelect
            labelId="simple-select-label"
            id="simple-select"
            value={type}
            onChange={e => onFileChange('type', e.target.value)}
          >
            <MatMenuItem value="svg_realtext" selected>向量可選字</MatMenuItem>
            <MatMenuItem value="svg_shapetext">向量不可選字</MatMenuItem>
            <MatMenuItem value="image_realtext">圖片可選字</MatMenuItem>
          </MatSelect>
        </MatFormControl>
        <MatFormControlLabel
          control={
            <MatCheckbox
              color="primary"
              checked={compressImg}
              onChange={event => onFileChange('compressImg', event.target.checked)}
            />
          }
          label="轉檔時壓縮pdf文件內圖檔"
        />
      </UiTopContent>
      <DropzoneUploader filesLimit={1} onChange={value => onFileChange('file', value)} acceptedFiles={['.pdf']} />
    </UiPdfUploader>
  );
};

PdfUploader.propTypes = {
  getFileHandler: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};


