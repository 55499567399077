import styled from 'styled-components';

export const UiHeader = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	padding: 10px 0;
	width: 100%;
	font-size: 2rem;
	background-color: #fff;
	z-index: 999;
	box-shadow: 1px 1px 3px rgba(51, 51, 102, 0.5);
`;

export const UiContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 80%;
`;

export const UiLogo = styled.div`
	display: flex;
	align-items: center;
`;

export const UiText = styled.div`
	margin-left: 20px;
`;

export const UiUser = styled.div`
`;
