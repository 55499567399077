import React, { useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useBook } from 'store/book';
import { useTag } from 'store/tag';
import { format } from 'date-fns';
import { useSetState } from 'utils/hooks/useSetState';
import { EDIT_STATUS } from 'constants/index';
import { Pagination } from '@material-ui/lab';
import { MenuItem } from '@material-ui/core';
import queryString from 'query-string';
import noImg from 'assets/images/noImg.png';
import {
  UiTable,
  UiTableTitle,
  UiTableContent,
  UiTableContentItem,
  UiTableItem,
  UiSelect,
  UiTextField,
  UiDeleteVerifyWrap,
  UiVerifyErrorMsg
} from 'styles';
import {
  UiBookList,
  Uioption,
  UiSearch,
  UisearchOptions,
  UiBtn,
  UiPagination,
  UiErrorMsg
} from './BookList.style';

import { Button, Loading, Modal, DateTimePicker } from 'components';


/**
 * 書城列表
 */
export const BookList = () => {
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const page = params.page;
  const [{
    options,
    school,
    education,
    grade,
    subject,
    filterInput,
    filterDate,
    modalState,
    modalDeleteState,
    deleteVerifyInput,
    errorMessage,
    currentPage,
    locationParams
  }, setState] = useSetState({
    options: 'displayName',
    school: '',
    education: '',
    grade: 1,
    subject: '',
    filterInput: '',
    filterDate: new Date(new Date().toLocaleDateString()).getTime(), // 今天日期（時間戳）
    modalState: {
      isOpen: false,
      title: '',
      text: '',
      editData: {},
    },
    modalDeleteState: {
      isOpen: false,
      title: '',
      text: '',
      editData: {},
    },
    deleteVerifyInput: {
      inputContent: '',
      isError: false
    },
    errorMessage: '',
    currentPage: page || 1,
    locationParams: null
  });

  // store
  const [{ books, isLoding }, { getBooksList, getBookById, updateBook, deleteBook }] = useBook();
  const { data: booklist, total } = books;

  const [{ tags }, { getTags }] = useTag();
  const { dataMap: { SchoolYear, EducationalSystem, Grade, Subject } } = tags;

  // 切換頁數
  const onPaginationChange = page => {
    setState({ currentPage: page });
    const query = queryString.stringify({ ...params, page });
    history.push({
      pathname: location.pathname,
      search: `?${query}`
    });
    switch (options) {
      case 'schoolYear':
      case 'educationLevel':
      case 'grade':
      case 'subject':
        getBooksList({ tags: [selectedTagsValue(options)], page: page });
        break;
      case 'id':
        getBookById(filterInput);
        break;
      case 'publishedAt':
      case 'unpublishedAt':
        getBooksList({ [options]: filterDate, page: page });
        break;
      default:
        getBooksList({ [options]: filterInput, page: page });
        break;
    }
  };

  // 篩選器
  const onSelectChange = e => {
    const value = e.target.value;
    setState({
      options: value,
      filterInput: ''
    });
  };

  // 學制篩選器
  const onSchoolChange = e => {
    const year = e.target.value;
    setState({ school: year });
  };

  // 學制篩選器
  const onEducationChange = e => {
    const level = e.target.value;
    setState({ education: level });
  };

  // 年級篩選器
  const onGradeChange = e => {
    const year = e.target.value;
    setState({ grade: year });
  };

  // 科目篩選器
  const onSubjectChange = e => {
    const value = e.target.value;
    setState({ subject: value });
  };

  // 篩選input
  const oninputChangeHandle = e => {
    setState({ filterInput: e.target.value });
  };

  // 篩選時間
  const onDateTimeChangeHandle = date => {
    setState({ filterDate: date });
  };

  const selectedTagsValue = useCallback((options) => {
    switch (options) {
      case 'schoolYear':
        return school;
      case 'educationLevel':
        return education;
      case 'grade':
        return grade;
      case 'subject':
      default:
        return subject;
    }
  }, [school, education, grade, subject]);

  // 篩選送出
  const onSubmitFilterHandle = () => {
    let searchCriteria = '';
    switch (options) {
      case 'schoolYear':
      case 'educationLevel':
      case 'grade':
      case 'subject':
        getBooksList({ tags: [selectedTagsValue(options)], page: currentPage });
        searchCriteria = selectedTagsValue(options);
        break;
      case 'id':
        getBookById(filterInput);
        searchCriteria = filterInput;
        break;
      case 'publishedAt':
      case 'unpublishedAt':
        getBooksList({ [options]: filterDate, page: currentPage });
        searchCriteria = filterDate;
        break;
      default:
        getBooksList({ [options]: filterInput, page: currentPage });
        searchCriteria = filterInput;
        break;
    }
    setState({ currentPage: 1 });
    errorMessageHandle();

    if (searchCriteria !== '') {
      history.push({
        pathname: location.pathname,
        search: `?options=${options}&searchCriteria=${searchCriteria}&page=${currentPage}`,
      });
    } else {
      history.push({
        pathname: location.pathname,
        search: ''
      });
    }
  };


  const toggleBookPublish = (bookId, platform) => {
    const payloadKey = platform === 'web' ? 'isPublish' : 'isAppPublish';
    updateBook(bookId, { [payloadKey]: !modalState.editData[payloadKey] });
    
    const bookList = booklist.map(book => {
      if (book.bookId === bookId) {
        book[payloadKey] = !book[payloadKey];
      }
      return book;
    });

    setState({
      bookList
    });
  };

  const modalButtons = [
    {
      text: '確認',
      func: () => toggleBookPublish(modalState.editData.bookId, modalState.platform)
    },
    {
      text: '取消',
      color: 'highlight',
    }
  ];

  // 切換彈窗開關
  const getModalStateHandler = state => {
    setState({ modalState: { isOpen: state } });
  };

  // 上/下 架 事件
  const onPublishChange = list => {
    setState({
      modalState: {
        isOpen: true,
        title: list.isPublish ? '確定要下架Web？' : '確定要上架Web？',
        text: list.isPublish ? '確認下架後請重新整理畫面' : '確認上架後請重新整理畫面',
        editData: list,
        platform: 'web'
      },
    });
  };

  const onAppPublishChange = list => {
    setState({
      modalState: {
        isOpen: true,
        title: list.isAppPublish ? '確定要下架APP？' : '確定要上架APP？',
        text: list.isAppPublish ? '確認下架後請重新整理畫面' : '確認上架後請重新整理畫面',
        editData: list,
        platform: 'app'
      },
    });
  };

  const modalDeleteButtons = [
    {
      text: '確認',
      func: async () => {
        if (deleteVerifyInput.inputContent === '刪除') {
          const data = await deleteBook(modalDeleteState.editData.bookId);

          if (data.value.isSuccess) {
            getBooksList({ page: 1 });
            setState({
              ...modalDeleteState,
              modalDeleteState: { isOpen: false }
            });
          }
        } else {
          setState({
            ...deleteVerifyInput,
            deleteVerifyInput: {
              isError: true
            }
          });
        }
      }
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => {
        setState({
          ...modalDeleteState,
          modalDeleteState: { isOpen: false }
        });
      }
    }
  ];

  // 切換彈窗開關
  const getModalDeleteStateHandler = state => {
    setState({
      ...modalDeleteState,
      modalDeleteState: { isOpen: state }
    });
  };

  // 編輯事件
  const onEditBook = (book) => {
    history.push(`/books/bookform/${EDIT_STATUS.EDIT}/${book.bookId}${location.search}`);
  };

  // 刪除 事件
  const onDeleteChange = list => {
    setState({
      modalDeleteState: {
        isOpen: true,
        title: '您確定要刪除書本嗎？',
        text: '刪除後，會將書本從後台移除。若確定要刪除，請在以下欄位中輸入「刪除」並確認',
        editData: list
      },
    });
  };

  const verifyDeleteInput = e => {
    setState({
      ...deleteVerifyInput,
      deleteVerifyInput: {
        inputContent: e.target.value
      }
    });
  };

  const errorMessageHandle = () => {
    let msg = '';
    switch (options) {
      case 'id':
        msg = '找不到書本，請檢查是否輸入完整及正確的書本 ID。';
        break;
      default:
        msg = '找不到書本。';
    }
    setState({ errorMessage: msg });
  };

  useEffect(() => {
    if (!locationParams) return;
    switch (locationParams.options) {
      case 'displayName':
      case 'id':
        setState({ filterInput: locationParams.searchCriteria });
        break;
      default:
        break;
    }
  }, [locationParams]);

  useEffect(() => {
    if (SchoolYear.length <= 0 || !locationParams) return;

    if (locationParams) {
      setState({ school: locationParams.searchCriteria });
    } else {
      setState({
        school: SchoolYear[0].label
      });
    }
  }, [SchoolYear, locationParams]);

  useEffect(() => {
    if (EducationalSystem.length <= 0 || !locationParams) return;

    if (locationParams) {
      setState({ education: locationParams.searchCriteria });
    } else {
      setState({
        education: EducationalSystem[0].label
      });
    }
  }, [EducationalSystem, locationParams]);

  useEffect(() => {
    if (Grade <= 0 || !locationParams) return;

    if (locationParams) {
      setState({ Grade: locationParams.searchCriteria });
    } else {
      setState({
        grade: Grade[0].label
      });
    }
  }, [Grade, locationParams]);

  useEffect(() => {
    if (Subject <= 0 || !locationParams) return;

    if (locationParams) {
      setState({ subject: locationParams.searchCriteria });
    } else {
      setState({
        subject: Subject[0].label
      });
    }
  }, [Subject, locationParams]);

  // 初始執行
  useEffect(() => {
    if (location.search !== '') {
      switch (params.options) {
        case 'schoolYear':
        case 'educationLevel':
        case 'grade':
        case 'subject':
          getBooksList({ tags: [params.searchCriteria], page: currentPage });
          break;
        case 'id':
          getBookById(params.searchCriteria);
          break;
        case 'publishedAt':
        case 'unpublishedAt':
          getBooksList({ [params.options]: params.searchCriteria, page: currentPage });
          setState({
            filterDate: parseInt(params.searchCriteria)
          });
          break;
        case 'displayName':
          getBooksList({ [params.options]: params.searchCriteria, page: currentPage });
          break;
        default:
          getBooksList({ page: currentPage });
          break;
      }
      setState({
        options: params.options,
      });
    } else {
      getBooksList({ page: currentPage });
    }
    getTags();
    setState({ locationParams: queryString.parse(location.search) });
  }, []);

  return (
    <UiBookList>
      <Uioption>
        <UiSearch>
          <UiSelect
            value={options}
            onChange={onSelectChange}
            variant='outlined'
          >
            <MenuItem value={'displayName'}>書本名稱</MenuItem>
            <MenuItem value={'id'}>書本 ID</MenuItem>
            <MenuItem value={'publishedAt'}>上架時間</MenuItem>
            <MenuItem value={'unpublishedAt'}>下架時間</MenuItem>
            <MenuItem value={'schoolYear'}>學年度</MenuItem>
            <MenuItem value={'educationLevel'}>學制</MenuItem>
            <MenuItem value={'grade'}>年級</MenuItem>
            <MenuItem value={'subject'}>科目</MenuItem>
          </UiSelect>
          <UisearchOptions>
            {
              (options === 'displayName') && <UiTextField variant='outlined' onChange={oninputChangeHandle}
                value={filterInput} />
            }
            {
              (options === 'id') && <UiTextField variant='outlined'
                onChange={oninputChangeHandle}
                placeholder='請輸入完整書本 ID'
                value={filterInput} />
            }
            {
              (options === 'publishedAt' || options === 'unpublishedAt') && <DateTimePicker value={filterDate}
                onChange={onDateTimeChangeHandle} />
            }
            {
              options === 'schoolYear' && <UiSelect
                value={school}
                onChange={onSchoolChange}
                variant='outlined'
              >
                {
                  SchoolYear.length > 0 && SchoolYear.map(item =>
                    <MenuItem value={item.tagId} key={item.tagId}>{item.label}</MenuItem>)
                }
              </UiSelect>
            }
            {
              options === 'educationLevel' && <UiSelect
                value={education}
                onChange={onEducationChange}
                variant='outlined'
              >
                {
                  EducationalSystem.length > 0 && EducationalSystem.map(item =>
                    <MenuItem value={item.tagId} key={item.tagId}>{item.label}</MenuItem>)
                }
              </UiSelect>
            }
            {
              options === 'grade' && <UiSelect
                value={grade}
                onChange={onGradeChange}
                variant='outlined'
              >
                {
                  Grade.length > 0 && Grade.map(item =>
                    <MenuItem value={item.tagId} key={item.tagId}>{item.label}</MenuItem>)
                }
              </UiSelect>
            }
            {
              options === 'subject' && <UiSelect
                value={subject}
                onChange={onSubjectChange}
                variant='outlined'
              >
                {
                  Subject.length > 0 && Subject.map(item =>
                    <MenuItem value={item.tagId} key={item.tagId}>{item.label}</MenuItem>)
                }
              </UiSelect>
            }
          </UisearchOptions>
          <Button onClick={onSubmitFilterHandle}>搜尋</Button>
        </UiSearch>
        <UiBtn>
          <Button onClick={() => history.push(`/books/bookform/${EDIT_STATUS.CREATE}`)}>新增</Button>
        </UiBtn>
      </Uioption >

      <UiTable>
        <UiTableTitle>
          <UiTableItem>書本縮圖</UiTableItem>
          <UiTableItem>書本名稱</UiTableItem>
          <UiTableItem>書本 ID</UiTableItem>
          <UiTableItem>上架時間</UiTableItem>
          <UiTableItem>下架時間</UiTableItem>
          <UiTableItem>操作</UiTableItem>
        </UiTableTitle>
        <UiTableContent>
          {
            booklist.length > 0 && booklist.map((item, index) => {
              return (
                <UiTableContentItem key={item.bookId}>
                  <UiTableItem>
                    <img onError={(e) => { e.target.onerror = null; e.target.src = noImg; }}
                      src={`${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${item.bookId}/cover.jpg`} alt="" />
                  </UiTableItem>
                  <UiTableItem>{item.displayName}</UiTableItem>
                  <UiTableItem>{item.bookId}</UiTableItem>
                  <UiTableItem>{item.publishedAt ?
                    format(new Date(item.publishedAt), 'yyyy-MM-dd HH:mm') : null}</UiTableItem>
                  <UiTableItem>{item.unpublishedAt ?
                    format(new Date(item.unpublishedAt), 'yyyy-MM-dd HH:mm') : null}</UiTableItem>
                  <UiTableItem>
                    <Button buttonColor="highlight"
                      onClick={() => onEditBook(item)}>編輯</Button>
                    <Button buttonColor="operate"
                      onClick={() => onPublishChange(booklist[index])}>{item.isPublish ? 'Web下架' : 'Web上架'}</Button>
                    <Button buttonColor="operate"
                      onClick={() => onAppPublishChange(booklist[index])}>
                        {item.isAppPublish ? 'APP下架' : 'APP上架'}
                    </Button>
                    <Button buttonColor="delete" onClick={() => onDeleteChange(booklist[index])}>刪除</Button>
                  </UiTableItem>
                </UiTableContentItem>
              );
            })
          }
        </UiTableContent>
      </UiTable>
      {booklist.length === 0 && <UiErrorMsg>{errorMessage}</UiErrorMsg>}
      <UiPagination>
        <Pagination count={Math.ceil(total / 10)} page={currentPage} onChange={(e, page) => onPaginationChange(page)} />
      </UiPagination>
      <Loading isLoading={isLoding} />
      <Modal
        autoClose
        isOpen={modalState.isOpen}
        title={modalState.title}
        text={modalState.text}
        buttons={modalButtons}
        getModalState={getModalStateHandler}
      />
      <Modal
        isOpen={modalDeleteState.isOpen}
        title={modalDeleteState.title}
        text={modalDeleteState.text}
        buttons={modalDeleteButtons}
        getModalState={getModalDeleteStateHandler}>
        <UiDeleteVerifyWrap>
          <UiTextField variant='outlined' onChange={verifyDeleteInput}></UiTextField>
          {deleteVerifyInput.isError ? <UiVerifyErrorMsg>請輸入正確內容</UiVerifyErrorMsg> : null}
        </UiDeleteVerifyWrap>
      </Modal>
    </UiBookList >
  );
};
