import React from 'react';
import PropTypes from 'prop-types';

import { ClickableUpload, Button } from 'components';

import { UiUploadButtonLabel } from './UploadButton.style';


/**
 * 上傳檔案按鈕
 */

export const UploadButton = ({ label, buttonColor, fileUrl, onChange }) => {
  return (
    <ClickableUpload onUploaded={onChange} fileUrl={fileUrl} isShowFileList={true}>
      <Button buttonColor={buttonColor} >
        <UiUploadButtonLabel>{label}</UiUploadButtonLabel>
      </Button>
    </ClickableUpload>
  );
};

UploadButton.propTypes = {
  label: PropTypes.string,
  fileUrl: PropTypes.string,
  onChange: PropTypes.func
};


