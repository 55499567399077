import React from 'react';
import PropTypes from 'prop-types';
import { UiButton, UiCircularProgress } from './Button.style';
/**
 * Use `Button` to highlight key info with a predefined status. Easy peasy!!!!
 */

export const Button = ({
  buttonColor = 'normal',
  textColor = '#fff',
  sizeType = 'small',
  children,
  onClick,
  disabled = false,
  loading = false,
  size = 24,
}) => {
  return (
    <UiButton
      buttonColor={disabled ? 'disable' : buttonColor}
      textColor={textColor}
      sizeType={sizeType}
      onClick={(!loading && !disabled) ? onClick : () => { }}
      disabled={loading || disabled}
    >
      {children}
      {loading && <UiCircularProgress size={size} />}
    </UiButton>
  );
};

Button.propTypes = {
  buttonColor: PropTypes.string,
  textColor: PropTypes.string,
  sizeType: PropTypes.PropTypes.oneOf(['large', 'normal', 'small']),
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.number,
};
