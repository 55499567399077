import styled from 'styled-components';

export const UiBookList = styled.div`
	width: 100%;
`;

export const Uioption = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 20px 0;
`;

export const UiSearch = styled.div`
	display: flex;
	align-items: center;
	width: 80%;

	& > button {
		margin-left: 10px;
	}
`;

export const UisearchOptions = styled.div`
	margin: 0 10px;
`;

export const UiBtn = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const UiPagination = styled.div`
	display: flex;
	justify-content: center;
	margin: 3rem 0;
`;

export const UiErrorMsg = styled.div`
	text-align: center;
`;
