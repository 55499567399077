import styled from 'styled-components';

export const UiTagList = styled.div`
`;

export const Uioption = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;
`;

export const UiDeleteVerifyWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const UiVerifyErrorMsg = styled.div`
	margin-left: 10px;
	color: #f00;
`;


export const UiActions = styled.div`
	display: flex;
	width: 100%;
	padding: 0 10px;
`;