import styled from 'styled-components';

export const UiLoginBtn = styled.div`
`;

export const UiDropdownBox = styled.div`
	position: relative;
`;

export const UiDropdownMenu = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	overflow: hidden;
	margin: 0.125rem 0 0;
	min-width: 10rem;
	max-height: 0;
	font-size: 1rem;
	text-align: center;
	color: #212529;
	background-color: #fff;
	border-radius: 0 0 0.25rem 0.25rem;
	list-style: none;
	z-index: 1000;
	box-shadow:
		0 1px 3px rgba(0, 0, 0, 0.12),
		0 1px 2px rgba(0, 0, 0, 0.24);
	transition: 0.5s;

	&.active {
		padding: 0.5rem;
		max-height: 300px;
	}
`;

export const UiDropdownItem = styled.div`
	padding: 13px 10px;
	font-weight: 400;
	color: #212529;
	cursor: pointer;

	&:hover {
		background-color: #f8f9fa;
	}

	&.active {
		display: none;
	}
`;

export const UiLoginInfo= styled.div`
	padding: 5px 10px;
	font-size: 1rem;
	white-space: nowrap;
	color: #fff;
	background-color: #d42d26;
	transition: 0.5s;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;

	&:hover {
		color: #414141;
		background-color: #f8bf01;
	}

	span {
		font-size: 1rem;
		vertical-align: middle;
	}

	.icon {
		margin-left: 5px;
		font-size: 12px;
		vertical-align: middle;
	}

	svg {
		display: none;
	}
`;
