import { Api } from 'services/api';
import { stringify } from 'query-string';

const BASE_PATH = '/admin/tags';

/**
 * 取得 所有標籤資訊
 *
 * @param {string} category
 *
 * @returns {object} all tag list
 */
export const getTags = async params => {
  const response = await Api.get(`/tags?${stringify(params)}`);
  return response;
};


/**
 * 更新標籤資訊
 *
 * @param {string} category
 * @param {string} label
 * @param {number} sort
 *
 * @returns {object} tag
 */
export const updateTag = async (tagId,params) => {
  const response = await Api.put(`${BASE_PATH}/${tagId}`, params);
  return response;
};


/**
 * 新增標籤
 *
 * @param {string} category
 * @param {string} label
 *
 * @returns {object} tag
 */
export const createTag = async params => {
  const response = await Api.post(BASE_PATH, params);
  return response;
};

/**
 * 刪除標籤
 *
 * @param {string} id
 *
 * @returns {object} status
 */
export const deleteTag = async tagId => {
  const response = await Api.delete(`${BASE_PATH}/${tagId}`);
  return response;
};
