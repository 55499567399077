export const COMMON_ACTION_TYPE = {
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE'
};

export const SOURCE_TYPE = {
  PRESET: 'preset',
  CUSTOM: 'custom'
};

export const EDIT_STATUS = {
  CREATE: 'create',
  EDIT: 'edit',
  READ: 'read',
};

// 學制
export const EDUCATION_LEVEL = {
  ELEMENTARY: 'E',
  JUNIOR: 'J',
  SENIOR: 'S',
};

// 年級
export const EDUCATION = {
  [EDUCATION_LEVEL.ELEMENTARY]: [
    { label: '1 年級', value: '1' },
    { label: '2 年級', value: '2' },
    { label: '3 年級', value: '3' },
    { label: '4 年級', value: '4' },
    { label: '5 年級', value: '5' },
    { label: '6 年級', value: '6' },
  ],
  [EDUCATION_LEVEL.JUNIOR]: [
    { label: '7 年級', value: '7' },
    { label: '8 年級', value: '8' },
    { label: '9 年級', value: '9' },
  ],
  [EDUCATION_LEVEL.SENIOR]: [
    { label: '10 年級', value: '10' },
    { label: '11 年級', value: '11' },
    { label: '12 年級', value: '12' },
  ],
};


export const TAGS_LABEL_MAP = {
  EducationalSystem: '學制',
  Grade: '年級',
  SchoolYear: '學年度',
  Subject: '科目'
};


export const OPTIONS_TYPE = {
  Name: 'name',
  Status: 'status',
  BookId: 'bookId'
};

export const STATUS_TYPE = {
  Processing: '轉檔中',
  Failed: '轉檔失敗',
  Waiting: '等待轉檔中'
};

export const Roles = {
  Editor: '編輯'
};


export const CONVERT_TYPE = {
  svg_realtext: '向量可選字',
  svg_shapetext: '向量不可選字',
  image_realtext: '圖片可選字',
  svg: '向量網頁',
  canvas: '點陣網頁',
};


export const BOOK_FLIP_TYPE = {
  LeftToRight: 'R',
  RightToLeft: 'L',
};

export const ENABLED_IN_BOOK_CASE_TYPE = {
  ALL: 'ALL',
  WEB: 'WEB',
  APP: 'APP',
};

export const ERROR_CODE_MESSAGE = {
  1000: '伺服器錯誤',
  1001: '請登入或者沒有操作權限',
  1002: '請登入或者沒有操作權限',
  1004: '該書本已經被加入書城',
  2002: '書本ID不存在'
};
