import React from 'react';
import { UiBookListPage } from './BookListPage.style';

import { BookList } from 'components';


/**
 * 書城列表頁面
 */

export const BookListPage = () => {
  return (
    <UiBookListPage><BookList /></UiBookListPage>
  );
};


