import styled from 'styled-components';

export const UiClickableUpload = styled.div`
	display: inline-flex;
	cursor: pointer;
`;

export const UiUploadFileName = styled.div`
	display: inline-flex;
	align-items: center;
	margin-left: 1rem;
	text-decoration: underline;
	color: #000;
`;
