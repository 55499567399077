import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSetState } from 'utils/hooks/useSetState';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneUploader } from 'components';
import { UiPdfUploader } from './CsvUploader.style';

/**
 * CSV 上傳組件
 */

const useTypoStyles = makeStyles({
  root: {
    marginBottom: '10px'
  }
});

export const CsvUploader = ({ getFileHandler }) => {
  const [{
    type,
    compressImg,
    file,
  }, setState] = useSetState({
    type: 'svg_realtext',
    compressImg: true,
    file: []
  });
  const onFileChange = (key, value) => {
    setState({
      [key]: value
    });
  };

  useEffect(() => {
    getFileHandler({
      type,
      compressImg,
      file
    });
  }, [type, compressImg, file]);

  return (
    <UiPdfUploader>
      <Typography variant='h5' classes={useTypoStyles()}>
        <div>
          一次僅上傳一個檔案，檔案類型為 .xlsx 檔。可上傳2種表格：
        </div>
        <div>
          <div>(1)需填2個欄位：code 、 id</div>
          <div>1.code 、 id 欄位中的數值都必須是唯一值(不可重複)，其中 id 欄代表真的卷號</div>
        </div>
        <div>
          <div>(2)需填6個欄位：code 、year(學年度)、education(學制)、subject(科目)、book(冊次)、chapter(章節)</div>
          <div>1.所有欄位均為必填</div>
          <div>2.code欄位中的數值必須是唯一值</div>
        </div>
      </Typography>
      <DropzoneUploader filesLimit={1} onChange={value => onFileChange('file', value)} acceptedFiles={['.xlsx']} />
    </UiPdfUploader>
  );
};

CsvUploader.propTypes = {
  getFileHandler: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};


