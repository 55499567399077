import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  UiModal,
  UiMatDialogActions,
  UiMatDialogTitle,
  UiMatDialogContent,
  UiMatDialogContentText,
} from './Modal.style';
import { makeStyles } from '@material-ui/core/styles';
import { Button, } from 'components';


/**
 * 彈窗可以新增標題、內容、按鈕文字&callBack 類型
 */

const useStyles = makeStyles({
  paper: {
    border: '1px solid #acdae8',
    'border-radius': '15px'
  },
  root: {
    padding: '8px 24px'
  }
});

export const Modal = ({ text, content, title, isOpen, buttons, getModalState, autoClose, children }) => {
  const [state, setState] = useState(false);
  const classes = useStyles();
  const modalStateHandler = (callBack) => {
    callBack && callBack();

    if (autoClose) {
      getModalState(!isOpen);
      setState(!isOpen);
    }
  };
  useEffect(() => {
    setState(isOpen);
  }, [isOpen]);
  return (
    <UiModal
      open={state || false}
      onClose={() => modalStateHandler()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ classes: { root: classes.paper } }}
      maxWidth={false}
    >
      {title && <UiMatDialogTitle className={classes.root}>{title}</UiMatDialogTitle>}
      <UiMatDialogContent>
        <UiMatDialogContentText>
          {text}
        </UiMatDialogContentText>
        {content}
        {children}
      </UiMatDialogContent>
      {buttons && <UiMatDialogActions>
        {buttons.map((button, index) => {
          return (
            <Button
              onClick={() => modalStateHandler(button.func)}
              key={button.text}
              buttonColor={button.color}
              autoFocus={index === 0 && true}
              loading={button.loading}
            >
              {button.text}
            </Button>
          );
        })
        }
      </UiMatDialogActions>
      }
    </UiModal>);
};


Modal.propTypes = {
  text: PropTypes.string,
  content: PropTypes.node,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      func: PropTypes.func
    })
  ),
  getModalState: PropTypes.func,
  children: PropTypes.any,
  autoClose: PropTypes.bool
};



